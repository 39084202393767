import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Link } from "gatsby";

const NotFoundPage = () => (
  <Layout>
    <div className="not-found-section">
      <SEO title="404: Not found" />
      <h1>PAGE NOT FOUND</h1>
      <p>Sorry, the page you are looking for doesn't exist.</p>
      <Link to="/">Home</Link>&nbsp;/&nbsp;
      <Link to="/collections/nothingness/">Nothingness</Link>
      &nbsp;/&nbsp;
      <Link to="/collections/passage-through-time/">Passage Through Time</Link>
      &nbsp;/&nbsp;
      <Link to="/collections/the-abyss/">The Abyss</Link>&nbsp;/&nbsp;
      <Link to="/collections/lost-and-found/">Lost and Found</Link>&nbsp;/&nbsp;
      <Link to="/collections/stolen-memories/">Stolen Memories</Link>
      &nbsp;/&nbsp;
      <Link to="/contacts/">Contacts</Link>&nbsp;/&nbsp;
      <Link to="/about/">About</Link>
    </div>
  </Layout>
);

export default NotFoundPage;
